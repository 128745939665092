import React from 'react';
import qs from "qs";
import { connect } from 'react-redux';
import { Toast, InputItem } from 'antd-mobile';
import { Head, AppBar, BgBox, CarInputKeyboard, NewModalCustomize } from '../components';
import { parkCurShop as parkCurShopStorage } from '@/util/storageManager';
import couponTop from '../images/coupon-top.png';
import couponEditIcon from '../images/coupon-edit-icon.png';
import { info as infoStorage } from '@/util/storageManager';
import __URL__ from "../../../../config/index";

import './index.less';
import classNames from '@/util/classNames';
const prefix = 'park-coupon';
const cx = classNames(prefix);

class COUPON extends React.Component {
    constructor(props) {
        super(props);
        const { search } = this.props.location;
		const obj = qs.parse(search);
		const code = obj.code || obj['?code'];
		const phone = obj.phone || obj['?phone'];
        if(phone){
            infoStorage.setItem({ mobile: phone, coupon_code: code });
        }
        const { mobile, coupon_code } = infoStorage.getItem() || {};
        if(!code && !coupon_code){
            Toast.info('参数错误');
        }
        this.state = {
            carList: [],
            hasError: false,
            status: -1,
            showNoBindModal: false,
            errorTip: '',
            showErrorModal: false,
            canReceive: false,
            showBindModal: false,
            value: phone || mobile || '',
            hour: '',
            code: code || coupon_code || '',
        };
        this.fetchCarList().then((carList) => {
            if (carList.length === 0) {
                this.setState({ showNoBindModal: true });
                return;
            }
            this.setState({ carList });
            this.fetchCouponStatus();
        });
    }

    fetchCouponStatus = async () => {
        const { code, value } = this.state;
        const ENV = process.env.NODE_ENV;
	    const domain = __URL__[ENV]
        window.fetch(`${domain.himikDomain}/xl-contentApi/coupon/getParkingCouponStatus.do?code=${code}&mobile=${value}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then(response => response.json())
        .then(data => {
            console.log('fetchCouponStatus data', data);
            if(data.code === 0){
                this.setState({ status: data.data.status, hour: `${data.data.hours || ''}` });
                if(data.data.status === 0){
                    this.setState({ canReceive: true });
                } else if(data.data.status === 1){
                    if (this.state.carList.length === 0) {
                        this.setState({ showNoBindModal: true });
                        return;
                    }
                    const { history } = this.props;
                    history.replace(`/park/coupon/result?carNumber=${this.state.carList[0].carNumber}&hour=${this.state.hour}`);
                } else {
                    const str = ['十分抱歉！由于有效期已过，本停车券无法领取。您可联系工作人员重新发放。', '', '', '已使用该停车券，无法重复领取'];
                    this.setState({ errorTip: str[data.data.status + 1], showErrorModal: true });
                }
                return;
            }
            this.setState({ errorTip: data.message || '接口请求失败', showErrorModal: true });
        })
    }

    fetchCarList = async () => {
        const json = await this.props.getCarList();
        const { result: { data: carList = [] } = {} } = json || {};
        return carList;
    };

    onReceive = async () => {
        if(!this.state.canReceive) {
            this.setState({ showErrorModal: true });
            return;
        }
        if (this.state.carList.length === 0) {
            this.setState({ showNoBindModal: true });
            return;
        }
        this.setState({ showBindModal: true });
    };

    onErrorClick = () => {
        if (this.state.hasError) {
            Toast.info('手机号输入错误');
        }
    };

    componentWillMount() {
        const {
            location: { pathname },
            getKtCarShopList,
        } = this.props;
        getKtCarShopList();
    }

    onChange = (value) => {
        // if (value.replace(/\s/g, '').length < 11) {
        //     this.setState({
        //         hasError: true,
        //     });
        // } else {
        //     this.setState({
        //         hasError: false,
        //     });
        // }
        this.setState({
            value,
        });
    };

    onSureReceive = () => {
        const ENV = process.env.NODE_ENV;
	    const domain = __URL__[ENV]
        window.fetch(`${domain.himikDomain}/xl-contentApi/coupon/receiveParkingCoupon.do`, {
            method: 'POST',
            body: JSON.stringify({
                code: this.state.code,
                carNumber: this.state.carList[0].carNumber,
            }),
            headers: {
                'Content-Type': 'application/json',
            },
        })
        .then(response => response.json())
        .then(data => {
            if(data.code == 0){
                this.setState({ showBindModal: false });
                const { history } = this.props;
                history.replace(`/park/coupon/result?carNumber=${this.state.carList[0].carNumber}&hour=${this.state.hour}`);
                return;
            }
            Toast.info(data.message || '接口请求失败');
        })
    };

    onClickEditCarNum = () => {
        const { history } = this.props;
        const { carList } = this.state;
        history.push(`/park/edit/${carList[0].id}?type=coupon`);
    };

    goToBind = () => {
        const { history } = this.props;
        history.push('/park/add?type=coupon');
    };

    render() {
        const { value, showErrorModal, showNoBindModal, showBindModal, carList, hour, errorTip } = this.state;
        const {
            location: { state: { empty } = {} },
        } = this.props;
        const { shopName = '' } = parkCurShopStorage.getItem() || {};

        return (
            <div className={prefix}>
                <div className={cx('top')}>
                    <img src={couponTop} />
                    <div className={cx('hour')}>{ hour }</div>
                </div>
                <div className={cx('title')}></div>
                {/* <div className={cx('phone-box')}>
                    <InputItem
                        type='phone'
                        placeholder=''
                        // error={this.state.hasError}
                        // onErrorClick={this.onErrorClick}
                        onChange={this.onChange}
                        value={this.state.value}
                        clear={true}
                    ></InputItem>
                </div> */}
                <div
                    onClick={this.onReceive}
                    className={cx('opt-box')}
                    style={{ opacity: value.replace(/\s/g, '').length < 11 ? 0.5 : 1 }}
                >
                    立即领取
                </div>
                <NewModalCustomize
                    visible={showErrorModal}
                    title={'温馨提示'}
                    content={
                        <div>
                            {errorTip}
                        </div>
                    }
                    footer={[
                        {
                            text: '知道了',
                            onPress: (e) => {
                                if (e) e.preventDefault();
                                this.setState({ showErrorModal: false });
                            },
                        },
                    ]}
                />
                <NewModalCustomize
                    visible={showNoBindModal}
                    title={'温馨提示'}
                    content={
                        <p>
                            <span>当前手机号暂未绑定车牌</span>
                        </p>
                    }
                    footer={[
                        {
                            text: '取消',
                            onPress: (e) => {
                                if (e) e.preventDefault();
                                this.setState({ showNoBindModal: false });
                            },
                        },
                        {
                            text: '去绑定',
                            onPress: (e) => {
                                if (e) e.preventDefault();
                                this.setState({ showNoBindModal: false });
                                this.goToBind();
                            },
                        },
                    ]}
                />
                <NewModalCustomize
                    visible={showBindModal}
                    title={'领取确认'}
                    content={
                        <div
                            style={{
                                color: '#4E5969',
                                textAlign: 'center',
                                fontFamily: 'PingFang SC',
                                fontSize: '14px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                fontWeight: 500,
                            }}
                        >
                            车牌号：
                            <div style={{ color: '#1D2129', marginRight: 12 }}>
                                {carList.length > 0 ? carList[0].carNumber : ''}
                            </div>
                            <img
                                src={couponEditIcon}
                                width={44}
                                onClick={(e) => {
                                    if (e) e.preventDefault();
                                    this.onClickEditCarNum();
                                }}
                            />
                        </div>
                    }
                    footer={[
                        {
                            text: '取消',
                            onPress: (e) => {
                                if (e) e.preventDefault();
                                this.setState({ showBindModal: false });
                            },
                        },
                        {
                            text: '确认',
                            onPress: (e) => {
                                if (e) e.preventDefault();
                                this.onSureReceive();
                            },
                        },
                    ]}
                />
            </div>
        );
    }
}

const key = 'parkNew';
export default connect(
    (state) => {
        return {
            parkNew: state[key],
            carFeeLoading: state.loading.effects[key].carFeeRecord,
            carBindLoading: state.loading.effects[key].carBind,
            carFeePreViewLoading: state.loading.effects[key].carFeePreView,
            ktCarShopListLoading: state.loading.effects[key].getKtCarShopList,
        };
    },
    (dispatch) => {
        return {
            getCarList: dispatch[key].carList,
            handleCarBind: dispatch[key].carBind,
            getCarFeeRecord: dispatch[key].carFeeRecord,
            carFeePreView: dispatch[key].carFeePreView,
            getKtCarShopList: dispatch[key].getKtCarShopList,
        };
    }
)(COUPON);
